import { Filters } from "@/types/contacts";
import { atoms } from "@/utils/helpers/atoms";
import { useAtom } from "jotai";
import { colors, scrollbarWidth } from "../../styles/global.styles";
import { Root } from "./ContactFilters.style";

function Filter({
  filter,
  ...props
}: { filter: Filters } & React.ComponentProps<"button">) {
  const [[contactListFilter], setContactListFilter] = useAtom(
    atoms.contacts.contactFilter
  );

  const handleClickVerseFilter = () => {
    // Trigger "verse" filter twice because sometimes the screen renders too quickly before the atom is actually set and sets an empty list
    setContactListFilter("verse");

    setTimeout(() => {
      setContactListFilter("verse");
    }, 1000);
  };

  return (
    <button
      {...props}
      css={{
        background: "none",
        border: "none",
        color: colors.secondaryTextColor,
        borderRight: "1px solid",
        borderColor: colors.secondaryTextColor,
        paddingRight: "0.625rem",
        paddingLeft: "0.625rem",
        "&:hover": {
          color: colors.primaryAccentColor,
        },
      }}
      onClick={() =>
        filter === "verse"
          ? handleClickVerseFilter()
          : setContactListFilter("all")
      }
      style={
        contactListFilter === filter
          ? { color: colors.primaryAccentColor }
          : undefined
      }
    />
  );
}

const ContactFilters = () => {
  return (
    <Root
      css={{
        width: `calc(100% - 3em + (${scrollbarWidth} + 1.5px) * 2)`,
      }}
    >
      <Filter id="allContactsFilter" filter="all">
        All Contacts
      </Filter>
      <Filter css={{ borderRight: "none" }} filter="verse">
        VERSE Contacts
      </Filter>
      {/*TODO RE ADD WHEN WE CAN SAVE Favorites */}
      {/* <Filter filter="verse">Favorites</Filter> */}
    </Root>
  );
};

export default ContactFilters;
